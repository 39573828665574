import {createRouter, createWebHistory} from 'vue-router'


const routes = [
    {
        path: '/index',
        name: 'home',
        component: () => import(/* webpackChunkName: "about" */ '@/views/Home.vue'),
        redirect: '/',
        children: [
            {
                path: '/',
                name: 'about',
                component: () => import(/* webpackChunkName: "about" */ '@/views/about/AboutMe.vue')
            },
            {
                path: '/website',
                name: 'website',
                component: () => import(/* webpackChunkName: "about" */ '@/views/website/Website.vue')
            },
            {
                path: '/project',
                name: 'project',
                component: () => import(/* webpackChunkName: "about" */ '@/views/project/Project.vue')
            },
            {
                path: '/life',
                name: 'life',
                component: () => import(/* webpackChunkName: "about" */ '@/views/life/Life.vue')
            },
        ]
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
