<template>
  <router-view/>
</template>

<style lang="scss">
@import "@/assets/theme/handler.scss";

html, body, #app {
  @include font_color("textColor");
}
</style>
