import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


//==================== CSS =========================
import "@/assets/font/font.css"
import "@/assets/css/global.css"
import 'animate.css';

//==================== JS =========================

//==================== Plugin =========================
// 创建实例
const app = createApp(App);

import ArcoVue from '@arco-design/web-vue';
import ArcoVueIcon from '@arco-design/web-vue/es/icon';
import '@arco-design/web-vue/dist/arco.css';

app.use(ArcoVue);
app.use(ArcoVueIcon);

import {DraggablePlugin} from '@braks/revue-draggable';

app.use(DraggablePlugin);

import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

import vue3videoPlay from "vue3-video-play";
import "vue3-video-play/dist/style.css";

app.use(vue3videoPlay)

import Viewer from 'v-viewer';
import 'viewerjs/dist/viewer.css';

app.use(Viewer)


import {GridLayout, GridItem} from 'grid-layout-plus'

app.component('GridLayout', GridLayout)
    .component('GridItem', GridItem);

// 创建实例
app.use(store)
    .use(router)
    .mount('#app')


// 路由守卫
router.beforeEach((async (to, from, next) => {
    NProgress.start();

    // 放行
    next();
}));

router.afterEach(((to, from) => {
    NProgress.done();
}));